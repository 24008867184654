(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/ui/form/assets/javascripts/postscript.js') >= 0) return;  svs.modules.push('/components/ui/form/assets/javascripts/postscript.js');

svs.ui = svs.ui || {};
svs.ui.form = svs.ui.form || {};
svs.ui.reactForm = svs.ui.reactForm || {};

svs.ui.form.Checkbox = svs.ui.reactForm.Checkbox;
svs.ui.form.Label = svs.ui.reactForm.Label;
svs.ui.form.RadioButton = svs.ui.reactForm.RadioButton;
svs.ui.form.RadioGroup = svs.ui.reactForm.RadioGroup;


 })(window);